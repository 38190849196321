import roadmapMobile from "../assets/images/roadmap-171123.webp";
import roadmapDesktop from "../assets/images/roadmap-171123.webp";
const Roadmap = () => {
  return (
    <section id="roadmap">
      <div className="relative overflow-hidden py-16">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto  text-lg">
            <h1>
              <span className="mt-2 block text-center text-3xl  font-mitr-bold leading-8 tracking-tight text-cyan-300 sm:text-4xl mb-5">
                RECO 1 YEAR ROADMAP
              </span>
            </h1>
            <img
              className="m-auto w-7/12 lg:right-0 lg:max-w-none xxxs:hidden xxs:hidden md:flex lg:flex xl:flex "
              src={roadmapDesktop}
            />
            <img
              className="m-auto w-full lg:right-0 lg:max-w-none sm:hidden md:hidden lg:hidden"
              src={roadmapMobile}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
