import React, { useEffect, useState } from "react";
import { getStats } from "../services/stats";

const useStats = () => {
  const [playGameCount, setPlayGameCount] = useState(null);

  const fetchStats = async () => {
    await getStats()
      .then((res) => {
        let totalPlayGame =
          res.data.data.play_game_count + res.data.data.play_tournament_count;
        setPlayGameCount(totalPlayGame);
      })
      .catch((err) => {
        console.log("error call => ", JSON.stringify(err));
      });
  };
  useEffect(() => {
    fetchStats();
  }, []);

  return {
    playGameCount,
  };
};

export default useStats;
