import arthurImg from "../assets/images/advisor-arthur.webp";
import sajidImg from "../assets/images/advisor-sajid.webp";
import shelaImg from "../assets/images/advisor-shela.webp";
import antonioImg from "../assets/images/advisor-antonio.webp";

const OurAdvisor = () => {
  const people = [
    {
      id: 1,
      name: "Sajid Rahman",
      role: "Managing Partner of MyAsiaVC",
      imageUrl: sajidImg,
      linkedinUrl: "https://www.linkedin.com/in/rahmansajid/",
    },
    {
      id: 2,
      name: "Arthur Constant",
      role: "Regional Gaming Division Lead SEA at Axiata Digital",
      imageUrl: arthurImg,
      linkedinUrl: "https://www.linkedin.com/in/arthurconstant/",
    },

    {
      id: 3,
      name: "Shela Tanado",
      role: "VP of Whale Division Zipmex",
      imageUrl: shelaImg,
      linkedinUrl: "https://www.linkedin.com/in/shela-tanado-7b624154/",
    },
    {
      id: 4,
      name: "Antonio Prawira",
      role: "Founder Passion Jewelry",
      imageUrl: antonioImg,
      linkedinUrl: "https://www.linkedin.com/in/antonio-prawira-71470a99/",
    },
  ];
  return (
    <section id="ouradvisor">
      <div
        id="desktop-advisor"
        className="xxxs:hidden xxs:hidden sm:hidden md:flex lg:flex xl:flex overflow-hidden"
      >
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12 lg:grid md:grid lg:grid-cols-4  grid-cols-4 lg:gap-8 lg:space-y-0">
            <div className="space-y-5 sm:space-y-4">
              <h2 className="text-3xl font-mitr-bold tracking-tight sm:text-4xl text-cyan-300">
                Our Advisor
              </h2>
              {/* <p className="text-xl text-white">
                                Nulla quam felis, enim faucibus proin velit, ornare id pretium. Augue ultrices sed arcu condimentum
                                vestibulum suspendisse. Volutpat eu faucibus vivamus eget bibendum cras.
                            </p> */}
            </div>
            <div className="lg:col-span-3 col-span-3">
              <div className="flex overflow-x-scroll pb-10 hide-scroll-bar overflow-hidden">
                <div className="flex flex-nowrap ml-10">
                  {people.map((person) => (
                    <div className="inline-block px-3" key={person.id}>
                      <div className="w-72 h-auto max-w-xs overflow-hidden rounded-lg  shadow-md  shadow-indigo-500/40 bg-gray-800 transition-shadow duration-300 ease-in-out hover:shadow-xl  hover:drop-shadow-2xl hover:shadow-indigo-500/40">
                        <div className="space-y-4 rounded-lg p-5">
                          <div className="aspect-w-3 aspect-h-2 relative">
                            <img
                              className="rounded-lg object-cover shadow-lg "
                              src={person.imageUrl}
                              alt=""
                            />
                            <div className="absolute bottom-0 left-0 right-0 px-4 py-2 bg-gray-800 opacity-80">
                              <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
                                <div>
                                  <h3 className="text-white font-bold font-mitr">
                                    {person.name}
                                  </h3>
                                  <p className="text-white font-mitr">
                                    {person.role}
                                  </p>
                                </div>
                                <ul
                                  role="list"
                                  className="flex justify-center space-x-5"
                                >
                                  <li>
                                    <a
                                      href={person.linkedinUrl}
                                      className="text-gray-400 hover:text-gray-300"
                                    >
                                      <span className="sr-only">LinkedIn</span>
                                      <svg
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="mobile-advisor" className="md:hidden lg:hidden xl:hidden">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12">
            <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
              <h2 className="text-3xl font-mitr-bold tracking-tight sm:text-4xl text-cyan-300 text-center ">
                Our Advisor
              </h2>
            </div>
            <ul
              role="list"
              className="space-y-12 xxs:grid xxs:grid-cols-2 xxs:gap-x-6 xxs:gap-y-12 xxs:space-y-0"
            >
              {people.map((person) => (
                <div
                  key={person.id}
                  className=" h-auto max-w-xs overflow-hidden rounded-lg  shadow-md  shadow-indigo-500/40 bg-gray-800 transition-shadow duration-300 ease-in-out hover:shadow-xl  hover:drop-shadow-2xl hover:shadow-indigo-500/40"
                >
                  <div className="space-y-4 rounded-lg p-2">
                    <li key={person.name}>
                      <div className="space-y-4">
                        <div className="aspect-w-3 aspect-h-2 relative">
                          <img
                            className="rounded-lg object-cover shadow-lg "
                            src={person.imageUrl}
                            alt=""
                          />
                          <div className="absolute bottom-0 left-0 right-0 px-2 py-2 bg-gray-800 opacity-80">
                            <div className="space-y-2 flex xl:flex xl:items-center xl:justify-between">
                              <div className="grow">
                                <h3 className="text-white font-bold text-xxs">
                                  {person.name}
                                </h3>
                                <p className="text-white text-x2s">
                                  {person.role}
                                </p>
                              </div>
                              <ul
                                role="list"
                                className="flex justify-center space-x-5"
                              >
                                <li>
                                  <a
                                    href={person.linkedinUrl}
                                    className="text-gray-400 hover:text-gray-300"
                                  >
                                    <span className="sr-only">LinkedIn</span>
                                    <svg
                                      className="h-3 w-3"
                                      aria-hidden="true"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                </div>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurAdvisor;
