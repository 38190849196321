import awsImg from "../assets/images/partner-aws.webp";
import coinmarketImg from "../assets/images/partner-coinmarket.webp";
import unityImg from "../assets/images/partner-unity.webp";
import octopusnetworkImg from "../assets/images/partner-octopus.webp";
import sribuStartupImg from "../assets/images/partner-1000startup.webp";
import gempadImg from "../assets/images/partner-gempad.webp";
import metaoneImg from "../assets/images/partner-metaone.webp";
import humanImg from "../assets/images/partner-humanguild.webp";
import nearImg from "../assets/images/partner-near.webp";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
const Cloud = () => {
  return (
    <div className="bg-gradient-to-t from-partner-1 to-partner-2 ">
      <div className="mx-auto max-w-7xl py-8 px-4 sm:px-6 lg:px-8">
        {/* <div className="mt-6 grid grid-cols-3 gap-8 md:grid-cols-3    xxs:grid-cols-1 lg:grid-cols-3">
              <div className=" flex justify-center "> 
                <img className="h-12" src={awsImg} alt="AWS" />
              </div>
              <div className=" flex justify-center ">
                <img className="h-12" src={coinmarketImg} alt="Coin Market" />
              </div>
              <div className=" flex justify-center">
                <img
                  className="h-12" src={unityImg} alt="Unity" />
              </div>
              <div className=" flex justify-center col-span-3">
                <img
                  className="h-12" src={octopusnetworkImg} alt="Octopus Network" />
              </div>
            </div> */}
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          slidesPerGroup={1}
          centeredSlides={true}
          loop={true}
          loopFillGroupWithBlank={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide className="h-10 justify-center" zoom="false">
            <img className="h-12" src={coinmarketImg} alt="Coin Market" />
          </SwiperSlide>
          <SwiperSlide className="h-10 justify-center" zoom="false">
            <a
              target="_blank"
              href="https://medium.com/oct-network/octopus-accelerator-star-prize-winners-winter-2022-997b0d150556"
            >
              <img
                className="h-12"
                src={octopusnetworkImg}
                alt="Octopus Network"
              />
            </a>
          </SwiperSlide>
          <SwiperSlide className="h-10 justify-center" zoom="false">
            <a target="_blank" href="https://www.instagram.com/p/Coe5t4Yy3DS">
              <img
                className="h-12"
                src={sribuStartupImg}
                alt="Gerakan Nasional 1000 Startup"
              />
            </a>
          </SwiperSlide>
          <SwiperSlide className="h-10 justify-center" zoom="false">
            <img className="h-12" src={gempadImg} alt="Gempad" />
          </SwiperSlide>
          <SwiperSlide className="h-10 justify-center" zoom="false">
            <img className="h-12" src={metaoneImg} alt="Meta One" />
          </SwiperSlide>
          <SwiperSlide className="h-10 justify-center" zoom="false">
            <a
              target="_blank"
              href="https://app.astrodao.com/dao/human.sputnik-dao.near/proposals/human.sputnik-dao.near-218"
            >
              <img className="h-12" src={humanImg} alt="Human Guild" />
            </a>
          </SwiperSlide>
          <SwiperSlide className="h-10 justify-center" zoom="false">
            <a
              target="_blank"
              href="https://gov.near.org/t/proposal-reco-hexaplay-social-interactive-mini-mobile-games-competition-on-near/33464"
            >
              <img className="h-12" src={nearImg} alt="Near" />
            </a>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Cloud;
