import Hero from "../../components/Hero";
import Cloud from "../../components/Cloud";
import About from "../../components/About";
import Stats from "../../components/Stats";
import Footer from "../../components/Footer";
import Projects from "../../components/Projects";
import Roadmap from "../../components/Roadmap";
import OurTeam from "../../components/OurTeam";
import OurAdvisor from "../../components/OurAdvisor";
import useStats from "../../hooks/useStats";

const Home = () => {
  const { playGameCount } = useStats();

  return (
    <div className="bg-body-bg bg-cover bg-no-repeat">
      {/* <Header2/> */}
      {/* <Header/> */}

      <main>
        {/* Hero section */}

        <Hero />

        {/* Logo Cloud */}
        <Cloud />

        {/* About */}
        <About />

        {/* Projects */}
        <Projects />

        {/* Roadmap */}
        <Roadmap />

        <OurTeam />

        <OurAdvisor />

        {/* Stats section */}
        <Stats playGameCount={playGameCount} />

        {/* CTA Section */}
        {/* <CTA /> */}
      </main>

      <Footer />
    </div>
  );
};

export default Home;
