import React, { useRef, useState } from "react";
import mathImg from "../assets/images/game-math.webp";
import truefalseImg from "../assets/images/game-truefalse.webp";
import matchitImg from "../assets/images/game-matchit.webp";
import logicquizImg from "../assets/images/game-logicquiz.webp";
import headcountImg from "../assets/images/game-headcount.webp";
import jankenponImg from "../assets/images/game-jankenpon.webp";
import hexajumpImg from "../assets/images/game-hexajump.webp";
import hexasweeperImg from "../assets/images/game-hexasweeper.webp";

import comingImg from "../assets/images/comingsoon-project.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
  icon,
} from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const Projects = () => {
  const [swiperRef, setSwiperRef] = useState(null);

  const projects = [
    {
      id: 1,
      title: "Math Quiz",
      href: "#",
      description:
        "Math is hard. We all know that but hey at least with Reco, we can get prizes",
      imageUrl: mathImg,
    },
    {
      id: 2,
      title: "True or False",
      href: "#",
      description:
        "Become the most knowledgeable in the world and get rewarded.",
      imageUrl: truefalseImg,
    },
    {
      id: 3,
      title: "Match It",
      href: "#",
      description: "Improve your memory and dexterity by playing match it!",
      imageUrl: matchitImg,
    },
    {
      id: 4,
      title: "Jankenpon",
      href: "#",
      description: "Test your luck by playing jankenpon versus other player!",
      imageUrl: jankenponImg,
    },
    {
      id: 5,
      title: "Hexa Jump",
      href: "#",
      description: "How many platforms can you conquer in this gravity-defying adventure?",
      imageUrl: hexajumpImg,
    },
    {
      id: 6,
      title: "Hexa Sweeper",
      href: "#",
      description: "Sweep. Spot. Clear it.",
      imageUrl: hexasweeperImg,
    },
    {
      id: 7,
      title: "Logic Quiz",
      href: "#",
      description: "Coming Soon",
      imageUrl: logicquizImg,
    },
    {
      id: 8,
      title: "Head Count",
      href: "#",
      description: "Coming Soon",
      imageUrl: headcountImg,
    },
  ];

  return (
    <section id="projects">
      <div
        id="desktop-projects"
        className="xxxs:hidden xxs:hidden sm:hidden md:flex lg:flex xl:flex "
      >
        <div className="relative py-16 px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28 mx-auto">
          <div className="absolute inset-0">
            <div className="h-1/3 sm:h-2/3" />
          </div>
          <div className="relative mx-auto max-w-7xl">
            <div className="text-center">
              <h2 className="text-3xl font-mitr-bold tracking-tight text-cyan-300 sm:text-4xl">
                ULTIMATE GAME PLAY
              </h2>
              {/* <p className="mx-auto mt-3 max-w-2xl text-xl text-white sm:mt-4">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa libero labore natus atque, ducimus sed.
              </p> */}
            </div>
            <div className="mt-12 grid max-w-none gap-8 xxs:grid-cols-1 mx-auto md:grid-cols-2 lg:grid-cols-3 lg:max-w-none ">
              {projects.map((project) => (
                <div
                  key={project.id}
                  className="flex flex-col overflow-hidden rounded-xl shadow-md bg-gray-800 hover:shadow-xl hover:drop-shadow-2xl hover:shadow-cyan-500/40 hover:ring-1 hover:ring-cyan-300"
                >
                  <div className="shrink-0 mx-4 mt-4">
                    <img
                      className="max-h-96 w-full object-fill rounded-lg"
                      src={project.imageUrl}
                      alt=""
                    />
                  </div>
                  <div className="flex flex-col justify-between bg-gray-800 p-6">
                    <a href={project.href} className="mt-2 block">
                      <p className="text-xl font-semibold text-white font-mitr">
                        {project.title}
                      </p>
                      <p className="mt-3 text-base text-gray-200 font-mitr">
                        {project.description}
                      </p>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div id="mobile-project">
        <div className="mx-auto max-w-7xl md:hidden lg:hidden xl:hidden">
          <div className="text-center">
            <h2 className="text-3xl font-mitr-bold tracking-tight text-cyan-300 sm:text-4xl">
              ULTIMATE GAME PLAY
            </h2>
            {/* <p className="mx-auto mt-3 max-w-2xl text-xl text-white sm:mt-4">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa libero labore natus atque, ducimus sed.
            </p> */}
          </div>
          <Swiper
            onSwiper={setSwiperRef}
            initialSlide={1}
            slidesPerView={1.5}
            centeredSlides={true}
            spaceBetween={10}
            navigation={false}
            className="mySwiper"
          >
            {projects.map((project) => (
              <SwiperSlide key={project.id}>
                <div
                  className="w-full overflow-hidden rounded-lg  shadow-sm  
                  bg-gray-800 m-5
                hover:shadow-lg  hover:drop-shadow-xl hover:shadow-cyan-500/40 hover:ring-1 hover:ring-cyan-300 mx-2"
                >
                  <div className="flex-shrink-0 mx-2 mt-2">
                    <img
                      className="h-auto w-full  object-fill rounded-lg"
                      src={project.imageUrl}
                      alt=""
                    />
                  </div>
                  <div className="flex flex-1 flex-col justify-between bg-gray-800 p-6">
                    <div className="flex-1">
                      <a href={project.href} className="mt-2 block">
                        <p className="text-xl font-semibold text-white font-mitr">
                          {project.title}
                        </p>
                        <p className="mt-3 text-base text-gray-200 font-mitr">
                          {project.description}
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="text-center mt-3">
            <button
              onClick={() => swiperRef.slidePrev()}
              className="text-white"
            >
              <FontAwesomeIcon
                icon={icon({ name: "circle-chevron-left", style: "solid" })}
                className="text-3xl ml-2"
              />
            </button>

            <button
              onClick={() => swiperRef.slideNext()}
              className="text-white"
            >
              <FontAwesomeIcon
                icon={icon({ name: "circle-chevron-right", style: "solid" })}
                className="text-3xl ml-2"
              />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
