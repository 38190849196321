import whatimage from "../assets/images/about-char.webp";
import bgabout from "../assets/images/bg-about.webp";
import { InboxIcon, SparklesIcon } from "@heroicons/react/24/outline";
const About = () => {
  return (
    <section id="about">
      <div className="relative overflow-hidden pt-16 py-16  xxs:pb-0 xs:pb-0 sm:pb-0 md:pb-0 lg:pb-32 pb-32">
        <div className="mt-24">
          <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
            <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
              <div className="-ml-48 xxxs:ml-4 xxs:ml-4 xxs:mb-4 pr-4 sm:pr-6 md:mx-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                <img
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:max-w-none"
                  src={whatimage}
                  alt="Customer profile user interface"
                />
              </div>
            </div>
            <div className="mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-0 lg:px-0">
              <div>
                <div className="mb-24">
                  <h1 className="text-3xl  font-mitr-bold  tracking-tight text-cyan-300">
                    What is RECO?
                  </h1>
                  <p className="mt-4 text-lg  text-blue-200 font-mitr">
                    Reco is a social, interactive, mini game competition on
                    mobile with prizes.
                  </p>
                  <p className="mt-4 text-lg  text-blue-200 font-mitr">
                    Players can compete in games such as True or False Quiz,
                    Math Problems, Brain Test and Match It! Compare your score
                    on the leaderboard and get prizes based on your
                    achievements.
                  </p>
                  <p className="mt-4 text-lg text-blue-200 font-mitr">
                    Reco uses cryptocurrency as one of our payment methods as
                    well as prizes so people around the world can participate in
                    our mini games and earn tokens.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
