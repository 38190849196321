import { useState } from "react";
import banner from "../assets/images/banner.webp";
import bannermain from "../assets/images/banner-main.png";
import bannermobile from "../assets/images/banner-mobile.webp";
import awsImg from "../assets/images/partner-aws.webp";
import coinmarketImg from "../assets/images/partner-coinmarket.webp";
import unityImg from "../assets/images/partner-unity.webp";
import { Modal, Button } from "flowbite-react";
import appStoreImg from "../assets/images/icon-download-ios.svg";
import playStoreImg from "../assets/images/icon-download-android.svg";

const Hero = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <section id="hero">
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
        <div className="">
          <div className="relative shadow-xl sm:overflow-hidden">
            <div className="absolute inset-0">
              <img
                className="h-full w-full object-cover xxxs:hidden xxs:hidden xs:hidden sm:flex md:flex lg:flex xl:flex "
                src={banner}
                alt="RecoToken"
              />
              <img
                src={bannermobile}
                className="h-full w-full object-cover md:hidden lg:hidden xl:hidden "
              />
              <div className="absolute inset-0 bg-gradient-to-rmix-blend-multiply" />
            </div>
            <div className="relative py-16 sm:py-24 lg:py-32 ">
              {/* <h1 className="text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
                
                  <span className="block text-white"> </span>
                  <span className="block text-indigo-200">customer support</span>
                </h1> */}

              <img
                src={bannermain}
                className="block mx-auto mt-6 lg:max-w-lg text-center h-60 xxs:p-5"
              />

              <p className="mx-auto mt-6 max-w-lg text-center text-xl text-white sm:max-w-3xl font-mitr">
                The most interactive real game competition with prizes.
                <br /> Realize your dreams with a game
              </p>

              <div className="mx-auto mt-10 lg:mb-60 max-w-sm sm:flex sm:max-w-none sm:justify-center">
                <div className="space-y-4 sm:mx-auto sm:inline-grid sm:grid-cols-3 sm:gap-5 sm:space-y-0 items-center  ">
                  <a
                    href="https://apps.apple.com/id/app/reco-casual-games-competition/id6443644322"
                    target="_blank"
                    className="flex font-mitr-bold items-center justify-center rounded-md px-4 py-3
                   shadow-sm"
                  >
                    <img
                      className="rounded-lg object-cover shadow-lg h-16"
                      src={appStoreImg}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.hexaplay.reco"
                    target="_blank"
                    className="flex font-mitr-bold items-center justify-center rounded-md px-4 py-3
                   shadow-sm"
                  >
                    <img
                      className="rounded-lg object-cover shadow-lg h-16"
                      src={playStoreImg}
                      alt=""
                    />
                  </a>

                  <a
                    onClick={() => setOpenModal(true)}
                    href="#"
                    className="h-14 flex py-3 font-mitr-bold items-center justify-center rounded-md border-transparent 
                  ring-2 ring-sky-500
                   bg-opacity-60 text-base font-medium text-white shadow-sm xxs:rounded-3xl xxs:mx-5
                   hover:bg-gradient-to-r hover:from-indigo-600 hover:via-blue-500 hover:to-sky-300 sm:px-8 sm:rounded-3xl"
                  >
                    View Trailer
                  </a>

                  <Modal
                    show={openModal === true}
                    onClose={() => setOpenModal(false)}
                  >
                    <Modal.Header>Trailer</Modal.Header>
                    <Modal.Body>
                      <div className="w-full mx-0 place-self-center aspect-w-16 aspect-h-9">
                        <iframe
                          width="600"
                          height="315"
                          src="https://www.youtube.com/embed/MhBjUhj6RCk"
                          className="w-full mx-0"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
              {/* <div className="mx-auto w-full absolute py-16  bg-violet-600 bottom-0 opacity-70">
              <div className="mt-6 grid grid-cols-3 gap-8 md:grid-cols-3 lg:grid-cols-3">
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                  <img className="h-12" src={awsImg} alt="AWS" />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                  <img className="h-12" src={coinmarketImg} alt="Coin Market" />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                  <img
                    className="h-12"
                    src={unityImg}
                    alt="Unity"
                  />
                </div>

              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
