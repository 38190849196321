import stats_char from "../assets/images/stats-char.webp";
import competitionImg from "../assets/images/competition.webp";
import { useEffect, useState } from "react";

import appStoreImg from "../assets/images/icon-download-ios.svg";
import playStoreImg from "../assets/images/icon-download-android.svg";
import useStats from "../hooks/useStats";
const Stats = (props) => {
  const [playGame, setPlayGame] = useState(0);
  const metrics = [
    {
      id: 1,
      stat: "8K+",
      emphasis: "Companies",
      rest: "use laoreet amet lacus nibh integer quis.",
    },
    {
      id: 2,
      stat: "25K+",
      emphasis: "Countries around the globe",
      rest: "lacus nibh integer quis.",
    },
    {
      id: 3,
      stat: "98%",
      emphasis: "Customer satisfaction",
      rest: "laoreet amet lacus nibh integer quis.",
    },
    {
      id: 4,
      stat: "12M+",
      emphasis: "Issues resolved",
      rest: "lacus nibh integer quis.",
    },
  ];
  function animateValue(obj, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = numberWithCommas(
        Math.floor(progress * (end - start) + start)
      );
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function loadStatsCounter(id) {
    let obj = document.getElementById(id);
    let target_counter = props.playGameCount;
    animateValue(obj, 0, target_counter, 5000);
  }
  useEffect(() => {
    loadStatsCounter("stats-counter");
    loadStatsCounter("stats-counter-mobile");
  }, [props.playGameCount]);

  useEffect(() => {
    setPlayGame(props.playGameCount);
  }, [props.playGameCount]);

  return (
    <section id="competition">
      <div
        id="desktop-stats"
        className="relative xxxs:hidden xxs:hidden sm:flex md:flex lg:flex xl:flex "
      >
        {/* <div className="absolute inset-x-0 bottom-0 h-80  xxs:h-40 xl:top-0 xl:h-full">
          <div className="h-full w-full xl:grid xl:grid-cols-2">
            <div className="h-full xl:relative xl:col-start-2">
              <img
                    className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                    src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
                    alt="People working on laptops"
                  />
              <div
                aria-hidden="true"
                className="absolute inset-x-0 top-0 h-32  xl:inset-y-0 xl:left-0 xl:h-full xl:w-32"
              />
            </div>
          </div>
        </div> */}
        <div className="mx-auto max-w-4xl px-4 sm:px-6 sm:w-full lg:max-w-7xl lg:px-8 xl:grid xl:grid-flow-col-dense xl:grid-cols-0 xl:gap-x-8">
          <div className="relative pt-12 pb-64 xl:col-start-1 sm:col-start-1 md:col-start-1 xl:pb-24 xxs:pb-20">
            <div className="relative bg-opacity-10 bg-gradient-to-t from-transparent to-gray-600 border-2 border-blue-800 rounded-xl grid grid-cols-7 gap-4 items-center justify-items-stretch ">
              <div className="text-right ml-24 h-96 xxs:ml-5 xxs:h-40 my-5 col-span-3 sm:h-64 md:h-96 justify-self-end">
                <img
                  src={stats_char}
                  className="xxs:h-40 w-auto sm:h-64 md:h-96"
                />
              </div>

              <div className="relative col-span-4">
                <p className="mt-3 text-2xl xxs:text-sm md:text-2xl font-mitr text-white">
                  Total Competition Played
                </p>
                <p
                  id="stats-counter"
                  className="mt-3 text-6xl font-bold xxs:text-2xl  md:text-4xl font-mitr-medium text-white counter-animation"
                >
                  {playGame}
                </p>
                <div className="mt-14 xxs:mt-5 md:mt-8 flex">
                  <a
                    href="https://apps.apple.com/id/app/reco-casual-games-competition/id6443644322"
                    target="_blank"
                    className="flex font-mitr-bold items-center justify-center rounded-md px-4 py-3
                    shadow-sm"
                  >
                    <img
                      className="rounded-lg object-cover shadow-lg h-16"
                      src={appStoreImg}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.hexaplay.reco"
                    target="_blank"
                    className="flex font-mitr-bold items-center justify-center rounded-md px-4 py-3
                    shadow-sm"
                  >
                    <img
                      className="rounded-lg object-cover shadow-lg h-16"
                      src={playStoreImg}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="mobile-stats"
        className=" max-w-7xl sm:hidden  md:hidden lg:hidden xl:hidden xxxs:mx-4 xxs:mx-5 mx-10 mb-10"
      >
        <div className="relative bg-opacity-10 bg-gradient-to-t from-transparent to-gray-600 border-2 border-blue-800 rounded-3xl grid grid-cols-1 gap-4 items-center justify-items-stretch ">
          <div className="h-96 xxs:h-40 xxxs:h-40 mx-auto mt-4">
            <img
              src={stats_char}
              className="xxs:h-40 xxxs:h-40 w-auto md:h-96"
            />
          </div>

          <div className="relative mx-auto">
            <p className="font-mitr mt-3 text-2xl xxxs:text-sm xxs:text-sm md:text-2xl xxxs:text-center text-white">
              Total Competition Played
            </p>
            <p
              id="stats-counter-mobile"
              className="mt-3 text-6xl xxxs:text-2xl  xxs:text-3xl font-mitr-medium font-bold text-white counter-animation  mx-auto text-center"
            >
              {playGame}
            </p>
            <p className="xxs:mt-5 xxxs:mt-5 xxxs:text-center">
              <a
                href="https://apps.apple.com/id/app/reco-casual-games-competition/id6443644322"
                target="_blank"
                className="flex font-mitr-bold items-center justify-center rounded-md px-4 py-3
                   shadow-sm"
              >
                <img
                  className="rounded-lg object-cover shadow-lg w-40"
                  src={appStoreImg}
                  alt=""
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.hexaplay.reco"
                target="_blank"
                className="flex font-mitr-bold items-center justify-center rounded-md px-4 py-3
                   shadow-sm"
              >
                <img
                  className="rounded-lg object-cover shadow-lg  w-40"
                  src={playStoreImg}
                  alt=""
                />
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stats;
