import logo from "../assets/images/logo.webp";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";

import AnchorLink from "react-anchor-link-smooth-scroll";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, NavLink, Outlet } from "react-router-dom";

import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  const navigations = [
    {
      id: 1,
      name: "About",
      href: "#about",
      offset: -100,
      type: "anchor",
    },
    {
      id: 2,
      name: "Projects",
      href: "#projects",
      offset: -50,
      type: "anchor",
    },
    {
      id: 3,
      name: "Our Game Competitions",
      href: "#competition",
      offset: 100,
      type: "anchor",
    },
    {
      id: 4,
      name: "Roadmap",
      href: "#roadmap",
      offset: -50,
      type: "anchor",
    },
    {
      id: 5,
      name: "Our Team",
      href: "#ourteam",
      offset: -50,
      type: "anchor",
    },
    {
      id: 6,
      name: "Whitepaper",
      href: "https://recotoken-bucket.s3.ap-southeast-1.amazonaws.com/documents/PDF+RECO+(18+MEI+UPDATE).pdf",
      offset: -50,
      type: "link",
    },
    // {
    //   id: 6,
    //   name: 'FAQ',
    //   href: '/faq',
    //   offset: 100,
    //   type: 'link'
    // },
  ];

  const handleScroll = () => {
    if (this.props.scrollToTarget) {
      this.props.scrollToTarget();
    }
  };
  let activeStyle = {
    textDecoration: "underline",
  };

  let activeClassName = "underline";

  return (
    <header>
      <Popover className="relative bg-gradient-to-b from-purple-gray-900 to-purple-gray-500">
        <div className="mx-auto xxxs:flex xxs:flex xs:flex sm:flex md:flex lg:flex xl:flex  max-w-7xl items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div className="flex-none justify-start xxxs:grow xxs:grow xs:grow md:grow lg:w-0 lg:flex-1 ">
            <a href="#">
              <span className="sr-only">RecoToken</span>
              <img
                className="w-auto xxxs:h-10 xxs:h-5 xs:h-10 sm:h-10 md:h-12 lg:h-18 xl:h-18 h:18 object-contain"
                src={logo}
              />
            </a>
          </div>
          <div className="-my-2 -mr-2 xs:grow lg:hidden lg:grow text-right">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group
            as="nav"
            className="hidden space-x-6 lg:flex items-stretch "
          >
            {navigations.map((nav) => {
              if (nav.type == "anchor") {
                return (
                  <HashLink
                    key={nav.id}
                    to={"/" + nav.href}
                    className="font-mitr-bold text-base uppercase text-white hover:text-sky-300"
                  >
                    {nav.name}
                  </HashLink>
                  // <NavLink key={nav.id} to={ '/'+nav.href }  offset={nav.offset} className='font-titan-one text-base uppercase text-white hover:text-sky-300'>
                  // {nav.name} </NavLink>
                );
              } else if (nav.type == "link") {
                return (
                  <a
                    href={nav.href}
                    target="_blank"
                    key={nav.id}
                    className="font-titan-one text-base  uppercase text-white hover:text-sky-300"
                  >
                    {nav.name}
                  </a>
                );
              }
            })}
            <div className="flex-none">
              <a
                href="https://pancakeswap.finance/swap?outputCurrency=0xDFfb63F9b88bE30292c32D6f0E7D5a6D3d4551ba"
                target="_blank"
                className="lg:grow-0 font-mitr-bold whitespace-nowrap uppercase ring-2 ring-sky-500 bg-gradient-to-tr from-indigo-600 via-blue-500 to-sky-300 px-1 py-1 text-white hover:bg-none hover:ring-2 hover:ring-sky-500 hover:text-white sm:px-8  sm:rounded-3xl"
              >
                Buy RECO
              </a>
              <a href="https://member.recotoken.com" target="_blank" className="lg:grow-0 ml-2 font-mitr-bold whitespace-nowrap uppercase border-solid ring-2 ring-sky-500 bg-gradient-to-tr px-1 py-1 text-white shadow-sm  hover:from-indigo-600 hover:via-blue-500 hover:to-sky-300 hover:text-white  sm:px-8 sm:rounded-3xl">
                 Login
                </a>
              {/* <a href="https://forms.gle/JHGFTMSHi81UjHx87" className="lg:grow-0 ml-2 font-mitr-bold whitespace-nowrap uppercase border-solid ring-2 ring-sky-500 bg-gradient-to-tr px-1 py-1 text-white shadow-sm  hover:from-indigo-600 hover:via-blue-500 hover:to-sky-300 hover:text-white  sm:px-8 sm:rounded-3xl">
                  Swap RECO
                </a> */}
            </div>
          </Popover.Group>
          {/* <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
              <a href="#" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-sky-300">
                Sign in
              </a>
              <a
                href="#"
                className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-purple-700 hover:to-indigo-700"
              >
                Sign up
              </a>
            </div> */}
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 z-30 origin-top-right transform p-2 transition lg:hidden"
          >
            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <div>
                    <img className="h-8 w-auto" src={logo} alt="Your Company" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5">
                <div className="grid grid-cols-1 gap-4">
                  {navigations.map((nav) => {
                    if (nav.type == "anchor") {
                      return (
                        <a
                          href={nav.href}
                          key={nav.id}
                          className="text-base  font-mitr-bold text-gray-900 hover:text-gray-700"
                        >
                          {nav.name}
                        </a>
                      );
                    } else if (nav.type == "link") {
                      return (
                        <a
                          href={nav.href}
                          target="_blank"
                          key={nav.id}
                          className="text-base  font-mitr-bold text-gray-900 hover:text-gray-700"
                        >
                          {nav.name}
                        </a>
                      );
                    }
                  })}
                  {/* <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                      About
                    </a>
                    <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Projects
                    </a>
                    <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Our Game Competitions
                    </a>
                    <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Roadmap
                    </a>
                    <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Our Teams
                    </a>
                    <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                    FAQ
                    </a> */}
                </div>
                <div className="mt-6 ">
                  <a
                    href="https://pancakeswap.finance/swap?outputCurrency=0xDFfb63F9b88bE30292c32D6f0E7D5a6D3d4551ba"
                    target="_blank"
                    className="font-mitr-bold flex w-full items-center justify-center rounded-md border ring-2 ring-sky-500 bg-gradient-to-tr from-indigo-600 via-blue-500 to-sky-300 px-1 py-1 text-white  xxs:rounded-3xl sm:rounded-3xl"
                  >
                    Buy RECO
                  </a>
                   <a href="https://member.recotoken.com" className="font-mitr-bold flex w-full mt-3 items-center justify-center rounded-md border border-transparent ring-2 border-sky-500 px-1 py-1 text-sky-500 shadow-sm sm:px-8 xxs:rounded-3xl sm:rounded-3xl"
                    >
                      Login
                    </a>
                  {/* <a href="https://forms.gle/JHGFTMSHi81UjHx87" className="font-mitr-bold flex w-full mt-3 items-center justify-center rounded-md border border-transparent ring-2 border-sky-500 px-1 py-1 text-sky-500 shadow-sm sm:px-8 xxs:rounded-3xl sm:rounded-3xl"
                    >
                      Swap RECO
                    </a> */}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      <Outlet />
    </header>
  );
};

export default Header;
